@import '../../../styles/app';

.root {
  h4 {
    font-size: 14px;
  }

  :global {
    .fc-grid th {
      text-transform: uppercase;
    }

    .fc .fc-today {
      background-color: rgba($purple, .2);
    }

    .fc-day-grid-event {
      margin: 0;
      padding: 0;
    }

    .fc-event {
      border: none;
      font-weight: $font-weight-normal;
      background-color: $gray-200;
      color: $text-color;
    }

    a.fc-event {
      height: auto;
      line-height: $line-height-base;
      width: 100%;
    }

    /* ****  Full Calendar Custom   **** */
    .full-calendar {
      margin-top: 10px;
    }

    .calendar-controls {
      .btn {
        font-size: $font-size-mini;
      }
    }

    .calendar-external-events {
      margin-top: 20px;

    }

    .widget-calendar {
      @include media-breakpoint-up(xl) {
        margin-top: -100px;
      }
    }
  }
}

.customExternalEvent {
  margin: 10px 0;
  padding: 6px;
  font-size: $font-size-mini;
  cursor: pointer;
  border-radius: $border-radius;
  background-color: $white;
  border: 1px solid #ccc;
  box-shadow: var(--widget-shadow);
}