.bigStatTitle {
  width: 220px;
  margin-right: 0.5rem;
}

.dropdown {
  :global {
    .dropdown-toggle {
      background-color: transparent !important;
      font-size: 90%;
      border-color: transparent;

      &:hover {
        border-color: #c4ccd4;
        background-color: transparent;
      }

      &:active,
      &:focus {
        background-color: transparent !important;
      }
    }
  }
}