.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

// div.fade{
//
// position: absolute;
// opacity: 0;
// visibility: hidden;
// padding: 10px;
//
// -webkit-transition: opacity 600ms, visibility 600ms;
// transition: opacity 600ms, visibility 600ms;
// }
.statAbsoluteBottom {
  position: absolute;
  bottom: 0;
}

.dateRangeButton{
  background-color: #616161;
  width: 100%;
  border-radius: 4px;
  display: table;
  color: white;
  height: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-size: 12px;
  padding-left: 7%;
  padding-right: 7%;
  cursor: pointer;
  text-align: center;
  transition: all 0.15s ease-in-out;
}

.dateRangeButton:hover{
  opacity: .75;
}

.dateRangeFilter {
  background-color: #ebecec;
  width: 100%;
  border-radius: 4px;
  display: table;
  color: rgba(0, 0, 0, 0.6);
  height: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-size: 12px;
  padding-left: 7%;
  padding-right: 7%;
  cursor: pointer;
  text-align: center;
  // box-shadow: 0px 2px 4px -2px rgb(24 25 26 / 36%);
}
.dateRangeFilter span {
  display: table-cell;
  vertical-align: middle;
}

.fc-button {
  display: none !important;
}
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.dropdown-toggle::after {
  display: none !important;
}
.Mui-selected {
  color: #423733 !important;
  font-weight: 700 !important;
}
.MuiTabs-indicator {
  background-color: #423733 !important;
}
.regionalReportStat {
  text-align: center;
}
.regionalReportStat h2 {
  width: 100%;
  font-size: 3rem !important;
}
.regionMarketSection {
  text-align: center;
}

#customTextEmail ul,
#customTextEmail li {
  padding-left: 2rem !important;
  list-style: disc !important;
}
.normalinputstretch {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  min-height: 22px;
  position: relative;
  background: white;
}
.ng-invalid {
  border-bottom: 1px solid #eb516d;
}

.normalinputstretch input {
  border: none;
  margin-top: 3px;
  background: transparent;
}

.refreshLogsTable {
  max-height: 250px !important;
  overflow: scroll;
}
.wysiwygToolbar {
  display: inline-block;
}
.abc-checkbox label::before {
  top: 0px !important;
}
.loadingScreenModal .modal-content {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: 0;
}
.check-width {
  min-width: 50px !important;
  width: 150px;
}
.loadingScreenModal {
  margin: 10rem auto !important;
}
.loadingScreenModal .modal-content .modal-body,
.loadingScreenModal .loader {
  color: white !important;
  background: transparent !important;
}

.emailPreviewBox {
  max-height: 350px !important;
  overflow: scroll !important;
  padding: 1%;
  background: white;
  width: 95%;
  border: solid 0.5px #798892;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
  margin-top: 2%;
}
.emailLogPreviewBox svg {
  max-height: 50px;
}
.goalsdropdown button {
  background: white;
}
.createdByText,
.createdByText p {
  margin-top: -10px;
  width: 100%;
}
.createdByText p {
  text-align: right;
}
.extrapadding {
  padding: 5% !important;
}
.emailLoader {
  background: white;
}
.emailliststyle {
  border-top: 1px solid #d6dee5;
}
#marketinput div {
  background: white;
}

#businesslineinput div {
  background: white;
}
#recipientInput div {
  background: white;
}
#brokerinput div {
  background: white;
}

.email-desc-cell {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

.email-list-section {
  max-height: 700px;
  overflow: scroll;
}
.react-bootstrap-table th {
  vertical-align: middle !important;
  color: white;
  background-color: #005892;
  border: none;
  font-size: 0.875rem;
}
.emailreportdesc {
  font-weight: 500 !important;
}
.emailStatus {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
}
.reportSection h2:first-child {
  font-weight: 600;
}

.square {
  width: 100%;
  padding-bottom: 100%;
}

.order {
  white-space: nowrap;
}
.reportSection {
  background-color: #f0f3f5;
  padding: 2%;
  border-radius: 10px;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.search-label {
  width: 100%;
}
.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table {
  overflow: scroll !important;
}

.highcharts-bar-series,
.highcharts-point {
  cursor: pointer !important;
}
.settings-left {
  width: 100%;
}

.stats-row {
  display: block;
}
.settings-section {
  margin-top: 3%;
}
.settings-key {
  font-weight: 400;
}
.settings-title {
  font-size: 22px;
  text-transform: uppercase;
}

.settingstable {
  text-align: left;
}

.highcharts-container {
  overflow: visible !important;
}
.highcharts-contextmenu {
  z-index: 100000;
}

.serviceLineBadge {
  display: inline-block;
  padding: 5px;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fda700;
  font-weight: 400;
  border-radius: 2px;
}

.confidentialBadge {
  display: inline-block;
  padding: 5px;
  color: white;
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: #e30615;
  font-weight: 400;
  border-radius: 2px;
}

.switch i::before {
  width: 20px !important;
  height: 20px !important;
}

.switch :checked + i {
  padding-right: 0;
  padding-left: 15px !important;
  background: #68ca66;
}

.name.market {
  font-weight: inherit !important;
  font-size: inherit !important;
}
// Colors for status
.text-open {
  color: rgb(26, 134, 208) !important;
}

.updatessection {
  padding: 20px;
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: var(--widget-shadow);
}

#deckgl-wrapper {
  position: relative !important;
}
#view-MapView {
  height: 400px !important;
}

.updateIconSection {
  display: none;
}

.min-height-map {
  min-height: 400px;
}

@media (min-width: 576px) {
  .updateIconSection {
    display: inline-block;
    width: 10%;
    text-align: center;
    i {
      font-size: 4rem !important;
      position: relative;
      top: 30px;
      left: -10px;
      opacity: 0.75;
    }
  }
  .mobileUpdateIconSec {
    display: none !important;
  }
}

.updateTextBody {
  text-align: left !important;
}
.updateText {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
}

.updateTypeActive {
  color: #495057 !important;
  background-color: #dfe6eb;
}

.filterbox-updates {
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: var(--widget-shadow);
  padding-top: 2%;
  padding-bottom: 2%;
}

@media (max-width: 1200px) {
  .filterbox-updates {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  }
}

.updateitemBody {
  display: inline-block;
  width: 90%;
  h4 {
    display: inline-block !important;
    width: 90%;
  }
  .mobileUpdateIconSec {
    width: 10%;
    text-align: center;
    display: inline-block;
  }
}

.text-jllred {
  color: #e30615 !important;
}

.jll-edit {
  background-color: #e30615 !important;
  color: white !important;
}

.filter-form {
  text-align: left;
  label {
    // padding-right: 20%;
    width: 50%;
    justify-content: left !important;
  }
  .toggle-radios {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 3%;
  }
  .filterform-input {
    width: 100%;
  }
}

.jll-opp-table {
  tbody {
    td {
      vertical-align: middle;
    }
  }
}

div.checkbox-ios {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  position: relative !important;
  padding: 0.5rem !important;
}

.text-pending {
  color: rgb(0, 87, 146) !important;
}
.text-won {
  color: rgb(104, 202, 102) !important;
}
.text-lost {
  color: rgb(253, 95, 0) !important;
}

.help-block .m-0 .align-right {
  text-align: right !important;
}

.ErrorPage_errorContainer__2dnQw {
  width: 100% !important;
}
label.switch {
  margin-bottom: 0rem !important;
}
.text-ellipsis {
  white-space: normal !important;
}

.scorecard-button {
  padding-top: 0.5% !important;
}

.DateRangePickerInput {
  background-color: #f8f9fa !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #005792 !important;
}
.DateInput_input {
  font-weight: inherit !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  background-color: #f8f9fa !important;
  color: #484848 !important;
  width: 100%;
  padding: 4px 4px 4px !important;
}

.react-bs-table-search-form input {
  margin-bottom: 2%;
}
.filterslabel label {
  width: 100%;
  margin-bottom: 2%;
}
._7ahQImyV4dj0EpcNOjnwA {
  white-space: normal !important;
}
.gltlabels {
  width: 50% !important;
  margin-bottom: 0px !important;
}
.Widget_widget__1-5Kj {
  box-shadow: none !important;
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff;
}

.initialLoader {
  z-index: 100;
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  align-items: center;
}
.initialLoader span {
  font-size: 10em;
  opacity: 0.5;
}

.market-stats-widget div.Widget_widgetBody__2s4cV.widget-body {
  padding: 0px 20px !important;
}

.title-block-wnotice {
  margin-bottom: 2rem !important;
}

.title-block-wnotice h1.page-title {
  margin: 0 !important;
}

.title-block-wnotice small.text-muted {
  margin-left: 0.5% !important;
}

.surveyComment {
  border-top: 1px solid #d6dee5;
}
.clientAddress {
  text-align: center;
  color: white;
  background-color: theme-color("primary");
}

.border-left-info {
  border-left: 12px solid theme-color("info");
  margin-bottom: 0px !important;
}

.border-left-primary {
  border-left: 12px solid theme-color("primary");
  margin-bottom: 0px !important;
}

.border-left-danger {
  border-left: 12px solid theme-color("danger");
  margin-bottom: 0px !important;
}

.border-left-success {
  border-left: 12px solid theme-color("success");
  margin-bottom: 0px !important;
}

.profileBorderBottom {
  border-bottom: 1px solid #e7e7e7;
}
.opportunityDetailRight {
  float: right;
}
.exportAndBack {
  width: 100%;
}
.exportAndBack ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.exportAndBack ul li {
  margin: 0 1% 0 1%;
  display: inline;
  float: right;
}

.opp-header {
  text-align: center;
  margin: 7% auto;
  width: 75%;
}

.profileBorderRight {
  border-right: 1px solid #e7e7e7;
}

#calendarLegend li {
  display: inline;
}

// div.fade.show{
//     position: relative;
//   visibility: visible;
//  opacity: 1;
//  -webkit-transition: opacity 600ms, visibility 600ms;
//  transition: opacity 600ms, visibility 600ms;
// }

.navbar {
  height: $navbar-height;
  border: none;
  font-weight: 500;
  justify-content: flex-start;

  .nav {
    height: 100%;
  }

  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      padding: 0.5rem;

      .la {
        font-size: 20px;
      }
    }
  }

  .nav > .nav-item > .nav-link.bs-tether-target.show {
    display: flex !important;
  }

  .input-group {
    width: 245px;
  }

  .form-control {
    line-height: 1.7;
    padding: 0.6rem 0.85rem;
  }

  .dropdown-menu {
    min-width: 11rem;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-lg;
  }
}

.progress-bar {
  @include box-shadow(none);
}

.progress {
  height: $spacer * 1.3;
  margin-bottom: $spacer / 2;
}

.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 5px;
}

.form-control {
  font-family: $font-family-base;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, background-color ease-in-out 0.15s;

  &:focus {
    box-shadow: none;
  }

  &.no-border {
    border: none;
    background-color: darken($input-bg, 5%);

    &:focus {
      background-color: darken($input-bg, 7%);
    }
  }
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  line-height: inherit;
  color: $text-muted;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: $font-weight-normal;
}

.input-sm {
  font-size: 13px;
}

.alert {
  font-size: $font-size-sm;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

/*  Badge */
// Add colors for badges
.badge {
  padding: 4px;
  font-weight: $font-weight-bold;
  font-size: 11px;
}

.badge-success,
.badge-info,
.badge-warning,
.badge-danger {
  color: $white;
}

.badge-white {
  @include badge-variant($white);
}

.badge-default {
  @include badge-variant($gray-300);
}

.badge {
  &.badge-pill {
    padding: 0.4em 0.6em;
    font-weight: 700;
  }

  .list-group-item > & {
    margin-top: 2px;
  }
}

.table-no-border {
  margin-left: -$table-cell-padding;
  margin-right: -$table-cell-padding;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    border-top: 0;
  }
}

.table-sm {
  font-size: $font-size-sm;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 6px;
  }

  &.table-no-border {
    margin-left: -4px;
    margin-right: -4px;
  }
}

.small,
small {
  font-size: 85%;
}

///////// Buttons //////////

.dropdown-toggle::after {
  font-family: LineAwesome, sans-serif;
  content: "\F110";
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  opacity: 0.8;
  font-size: 85%;
}

.btn-link {
  &:focus {
    box-shadow: none;
  }
}

.btn-secondary {
  border-color: $gray-400;
  color: $gray-800;
}

.btn-success,
.btn-info,
.btn-warning {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-gray {
  border-color: $gray-400;
  color: $gray-700;
}

.btn-default {
  border-color: $gray-400;
  color: $gray-800;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-secondary.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn {
  font-family: $font-family-sans-serif;
  background-image: linear-gradient(to right, #ffffff26, #fff0);

  .glyphicon {
    top: 3px;
  }
}

.btn,
.btn-inverse,
.btn-gray {
  &:not(.active) {
    box-shadow: none !important;
  }
}

.btn-rounded {
  @include border-radius(6px);
}

.btn-rounded-f {
  @include border-radius(50px);
}

.btn-outline {
  @include button-variant(transparent, $white);

  @include hover-focus() {
    background-color: rgba($white, 0.1);
  }
}

.btn > a {
  color: inherit;
}

///////// Dropdowns //////////

.dropdown {
  &.show {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.nav-item.dropdown {
  &.show {
    .nav-link.active {
      background: $navbar-link-active-bg;
      color: $navbar-link-active-color;
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  // IE8
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

///////// Alerts //////////

.alert {
  border: none;

  .btn {
    border: none;
  }

  button.close {
    font-weight: $font-weight-thin;
    font-size: 18px;
    text-shadow: none;
    opacity: 1;
    color: #fff;
    outline: none;
    padding: 0;
    position: static;

    &.alert-transparent {
      color: inherit;
      opacity: 1;
    }
  }
}

.alert-white button.close {
  color: $gray-600;
}

.alert-rounded {
  border-radius: 50px;
}

.alert-success {
  background: theme-color("success");
  color: $white;

  &.alert-transparent {
    color: theme-color("success");
    background: rgba(theme-color("success"), $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: theme-color("success");
  }
}

.alert-info {
  background: theme-color("info");
  color: $white;

  &.alert-transparent {
    color: theme-color("info");
    background: rgba(theme-color("info"), $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: theme-color("info");
  }
}

.alert-warning {
  background: theme-color("warning");
  color: $white;

  &.alert-transparent {
    color: theme-color("warning");
    background: rgba(theme-color("warning"), $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: theme-color("warning");
  }
}

.alert-danger {
  background: theme-color("danger");
  color: $white;

  &.alert-transparent {
    color: theme-color("danger");
    background: rgba(theme-color("danger"), $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: theme-color("danger");
  }
}

.alert-primary {
  background: theme-color("primary");
  color: $white;

  &.alert-transparent {
    color: theme-color("primary");
    background: rgba(theme-color("primary"), $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: theme-color("primary");
  }
}

///////// Breadcrumbs //////////

.breadcrumb {
  color: $gray-600;
  margin-bottom: 1.5rem;
  margin-top: 0;

  > .active {
    font-weight: $font-weight-semi-bold;
  }

  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: $gray-600;
}

///////// Glyphicons //////////

.glyphicon {
  vertical-align: middle;
}

///////// Font Awesome //////////

.navbar {
  .fa {
    &.fa-lg {
      line-height: 19px;
    }
  }
}

///////// Tooltips //////////

.bs-tether-target.show {
  display: inline-block !important;
}

.tooltip.in {
  opacity: 1;
}

.tooltip-inner {
  @include border-radius(3px);
}

///////// Base Elements //////////

.navbar-notifications {
  .list-group-item:hover {
    .progress {
      background: $white;
    }
  }

  .btn-link {
    color: #666;
    text-decoration: none;

    &:hover {
      color: #333;
    }
  }
}

///////// List Group //////////

.list-group {
  .list-group-item {
    background-color: transparent;
  }
}

.list-group-lg {
  .list-group-item {
    padding: 1.25rem;
  }
}

.list-group:not(.list-group-sortable) .list-group-item {
  border-left: none;
  border-right: none;
}

.list-group-item {
  @include transition(background 0.15s ease-in-out);

  &:first-child {
    border-radius: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: $gray-100;
  }

  &,
  &:hover {
    color: $text-color;
  }
}

////////// Headings //////////

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  small,
  .small {
    font-weight: $font-weight-thin;
    font-size: 75%;
    color: $text-muted;
  }
}

// Popovers

.popover {
  border: none;

  @include box-shadow(none);

  .popover-header {
    border-bottom: none;
  }
}

.popover-content {
  padding: 20px;
}

// Modal

.modal-body {
  background: $gray-200;
}

// Navs
// --------------------------------------------------

.nav-tabs {
  &.nav-item + .nav-item {
    margin-left: 0;
  }

  border-bottom: none;
  background-color: $gray-400;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  > .nav-item {
    margin-bottom: -2px;

    > .nav-link {
      padding: 12px 18px;
      border: none;
      color: $text-muted;

      .label {
        margin-bottom: -2px;
      }

      @include transition(color 0.15s ease-in-out);

      &:hover {
        background-color: transparent;
        color: $text-color;
      }
    }

    .nav-link.open {
      &,
      &:hover,
      &:focus {
        background-color: $nav-tabs-active-link-hover-bg;
        color: $nav-tabs-active-link-hover-color;
      }
    }

    .nav-link.active {
      &,
      &:hover,
      &:focus {
        background-color: $nav-tabs-active-link-hover-bg;
        color: $nav-tabs-active-link-hover-color;
        border: none;

        @include box-shadow(1px 1px 2px #ccc);
      }
    }
  }
}

.tab-content {
  position: relative;
  z-index: 1;
  background-color: $white;

  > .tab-pane {
    padding: $spacer * 2;
  }
}

//
// Panels
// --------------------------------------------------

.panel {
  background-color: transparent;
  border: none;
}

.panel-header {
  background-color: $gray-400;
  color: $gray-800;
  border-bottom: 1px solid transparent;

  @include border-top-radius(($border-radius));
}

// Code
// -----------------

code {
  font-size: 85%;
  background-color: theme-color("danger-light");
  color: $pink;
  padding: 0;
}

pre {
  display: block;
  padding: 6.5px;
  margin: 0 0 (1rem / 2);
  font-size: 13px;
  line-height: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  white-space: pre-line; /* css-3 */

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

/* -------------------------
--------- Messenger --------
---------------------------- */

@-webkit-keyframes ui-spinner-rotate-right {
  /* line 64, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 65, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(180deg);
  }

  /* line 66, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 67, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(360deg);
  }

  /* line 68, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  /* line 72, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 73, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(0deg);
  }

  /* line 74, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 75, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(180deg);
  }

  /* line 76, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-right {
  /* line 80, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 81, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(180deg);
  }

  /* line 82, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 83, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(360deg);
  }

  /* line 84, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-left {
  /* line 88, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 89, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(0deg);
  }

  /* line 90, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 91, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(180deg);
  }

  /* line 92, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-right {
  /* line 96, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 97, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(180deg);
  }

  /* line 98, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 99, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(360deg);
  }

  /* line 100, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  /* line 104, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 105, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(0deg);
  }

  /* line 106, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 107, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(180deg);
  }

  /* line 108, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

/* Messenger spinner */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}

/* line 120, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}

/* line 124, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

/* line 130, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

/* line 140, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}

/* line 143, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-left;
  -moz-animation-name: ui-spinner-rotate-left;
  -o-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

/* line 152, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}

/* line 155, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -moz-animation-name: ui-spinner-rotate-right;
  -o-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* ----------- React Bootstrap Table ----------- */
@include media-breakpoint-down(sm) {
  .react-bs-table table {
    table-layout: inherit;
  }

  .react-bs-table-pagination {
    .col-xs-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.rc-color-picker-wrap {
  line-height: 1;
}

.rst__rowContents,
.rst__moveHandle,
.rst__loadingHandle {
  box-shadow: none !important;
}

.rst__rowContents {
  border-radius: 0 $border-radius $border-radius 0 !important;
}

.rst__moveHandle,
.rst__loadingHandle {
  border-radius: $border-radius 0 0 $border-radius !important;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background-color: $gray-500 !important;
}

.rst__collapseButton,
.rst__expandButton {
  box-shadow: 0 0 0 1px $gray-700 !important;
}

//
// Accordion
// --------------------------------------------------

.accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fa {
    transition: $transition-base;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

//
// Font Awesome
// --------------------------------------------------

.circle {
  .fa {
    vertical-align: middle;
  }
}

//  Easy Pie Chart

.easy-pie-chart-md,
.easy-pie-chart-lg {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #798892;
}

.easy-pie-chart-md canvas,
.easy-pie-chart-lg canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.easy-pie-chart-md {
  line-height: 120px;
  height: 120px;
  width: 120px;
}

.easy-pie-chart-lg {
  line-height: 140px;
  height: 140px;
  width: 140px;
}

/*         Flot        */

.chart-tooltip {
  position: fixed;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
  border: 1px solid $gray-light;
  font-size: $font-size-mini;
  background-color: $white;
}

.card {
  box-shadow: var(--widget-shadow);
}

.calendar {
  border-radius: $widget-border-radius;
}

.line-chart-tooltip {
  position: absolute;
  box-sizing: content-box;
  z-index: 1000;
  border-radius: $tooltip-border-radius;
  padding: 5px 10px;
  background: rgba($tooltip-bg, 0.8);
  color: $tooltip-color;
  top: 0;
  left: 0;
  visibility: hidden;

  & * {
    font-size: $tooltip-font-size !important;
  }
}

///// Toastify /////
.Toastify {
  .Toastify__toast {
    font-family: Montserrat, sans-serif;
    border-radius: $border-radius;
    &.Toastify__toast--success {
      background: $success;
    }

    &.Toastify__toast--warn {
      background: $warning;
    }

    &.Toastify__toast--error {
      background: $danger;
    }

    &.Toastify__toast--info {
      background: $info;
    }

    &-body {
      text-align: center;
    }
  }

  .notification-close {
    height: $font-size-base;

    &:hover {
      color: darken($white, 10%);
    }
  }
}

//// React Tags Input ////
.react-tagsinput {
  &.react-tagsinput--focused {
    border-color: $input-focus-border-color;
  }

  .react-tagsinput-tag,
  .react-tagsinput-input {
    margin: 2px !important;
  }

  .react-tagsinput-tag {
    border-radius: $border-radius;
    background-color: $purple;
    color: white;
    border-color: darken($purple, 10%);
  }
}

//// Highcharts Width fix ////
.highcharts-container,
.highcharts-root {
  width: 100% !important;
}

//// React Joyride Shadow fix ////
.__floater {
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 10px) !important;
}

.table-striped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: #f8f9fa;
    }
  }
}

.btn-white {
  @include button-variant($white, $white);

  @include hover-focus() {
    background-color: rgba($white, 0.9);
  }
}

/* switch */

.switch {
  box-sizing: content-box;
  display: flex;
  align-items: center;
}

.switch input {
  display: none;
}

.switch i {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 20px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.switch i::before {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.switch :checked + i {
  padding-right: 0;
  padding-left: 20px;
  background: $success;
}

//// GAMIFICATION ////
.MuiTab-root {
  font-size: 13px !important;
}

.MuiTabs-flexContainer button:focus {
  outline: none !important;
}

.topScoreImage {
  width: 80px;
  height: 80px;
}

.topScoreContent {
  flex: 1 0 auto;
}

.gamificationTableHeader {
  background-color: #eeeeee !important;
  text-align: center;
  border: none !important;
  color: #42373b !important;
}

.scorecardTableRow {
  max-height: 500px;
  overflow: scroll;
}

.scorecardTableTeamRow {
  max-height: 178px;
  overflow: scroll;
}

.scorecardTableRow th {
  position: sticky;
  font-size: 14px;
  top: 0;
}

.gamificationTableHeader {
  white-space: nowrap !important;
}

.teamScorecardLogo {
  float: right;
  height: 50px;
}

.winningShadowTop {
  border: solid 2px rgb(237, 28, 36);
  padding: 6%;
  transition: all 0.7s ease;
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}
.scorecardShadowTop {
  padding: 6%;
  transition: all 0.7s ease;
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}

.winningShadow {
  border: solid 2px rgb(237, 28, 36);
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}

.scorecardBottomRow {
  background-color: #d8d8d9;
  height: 30px;
  margin-bottom: -15px;
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.scorecardHeader {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}

.gamificationTableCell {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
}

.scorecardTableRow.row {
  margin-right: -22px !important;
}

.MuiPaper-elevation1 {
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}

.scorecardShadow {
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1) !important;
}
