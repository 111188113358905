@import '../../styles/app';

.section {
  margin-bottom: $spacer * 5;

  @include media-breakpoint-down(sm) {
    background-color: $white;
    margin: 0 -15px;

    & :global .hideon-sm {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionTitle {
  width: 100%;
  text-align: center;
  margin-bottom: $spacer * 2;
  font-weight: $font-weight-normal;
  color: theme-color('dark');

  @include media-breakpoint-down(sm) {
    font-size: 1.4rem;
    margin-bottom: $content-padding / 2;
  }
}
