@import '../../../../../../styles/app';

.table {
  td,
  th {
    font-weight: normal;
    align-items: center;
    min-width: 100px;
  }

  th {
    font-size: $font-size-sm;
  }

  tbody tr:nth-child(odd) {
    background-color: $gray-light;
  }
}
