@import '../../../../styles/app';

.bootstrapTable {
  border: none;

  :global .table {
    thead,
    tbody {
      th,
      td {
        border-right: none;
        border-left: none;
      }
    }

    thead th {
      background: transparent;
    }
  }
}


.react-bs-table .react-bs-container-footer > table > thead > tr > th, .react-bs-table .react-bs-container-header > table > thead > tr > th {
  border: none;
  background: $blue;
  color: $gray-100;
}

.ReactTable .rt-thead.-filters {
  border: none;
}

.order .fa .fa-sort{
  margin: 3px 0px 0px 3px;
}
.columnHead{
  font-size: 0.75rem;
}

.ReactTable .rt-thead{
  width: 100px;
}

.ReactTable .rt-thead .rt-tr {
  background: $blue;
  color: $gray-100;
}
