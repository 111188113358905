html {
  font-weight: 100;
  font-size: $font-size-initial;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  color: $text-color;
}

html,
body,
#app {
  height: 100%;
}

div.fade[style*="opacity:0"]{
  display: none;
}

div.fade[style*="opacity:1"]{
  display: block;
}


a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-weight: 600;
}

label {
  font-weight: 400;
}

strong {
  font-weight: 700;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

h1 small {
  font-size: 68%;
}
