@import '../../../../../../styles/app.scss';

:global {
  @import '../../../../../../../node_modules/react-datetime/css/react-datetime.css';
}

.wysiwygWrapper {
  border: 1px solid #ccc !important;
  overflow: visible;
  height: 270px;
}

.wysiwygToolbar {
  color: $gray-800 !important;
  background-color: #ddd !important;
  border-color: transparent !important;

  :global {
    .rdw-option-wrapper {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      height: 30px;
      min-width: 30px;
      margin: 0;
      background: #f8f8f8;
    }

    .rdw-dropdown-wrapper {
      background: #f8f8f8;
    }
  }
}

.wysiwygEditor {
  position: relative !important;
  overflow: hidden !important;
  height: 150px;
  line-height: 0.1;
}
.root {
  :global {
    .tab-content {
      overflow: visible;
    }
  }

  .formWizard {
    :global {
      .progress {
        margin-top: 6px;
        margin-bottom: 10px;
      }

      .form-group:last-child {
        margin-bottom: 0;
      }

      .pager {
        display: flex;
        margin: 18px 0;
        flex-direction: row;
        justify-content: space-between;

        .disabled button {
          cursor: not-allowed;
          opacity: 0.65;
        }
      }

      #datepicker .input-group {
        width: 100%;
        display: flex !important;

        .form-control {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }

        .input-group-addon {
          display: none;
        }
      }
    }
  }

  .wizardNavigation {
    margin: 0 (-0.5 * $spacer) 5px;

    :global {
      .active {
        background: theme-color('primary');

        &:hover {
          color: $white;
        }
      }
    }

    li {
      padding: 0 (0.5 * $spacer);
    }

    a {
      padding: $spacer;
      color: $text-color;
      background: $gray-300;

      strong {
        font-weight: $font-weight-semi-bold;
      }

      small {
        font-weight: $font-weight-thin;
      }
    }
  }

  .stepBody {
    padding: $spacer;
    background: $subtle-white;
    border-radius: $border-radius;
  }
}
