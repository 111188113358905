@import '../../styles/app';

.sidesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.analyticsSide {
  width: 80%;

  &:last-child {
    width: 20%;
    padding-left: $grid-gutter-width;
  }

  @media (max-width: 1620px) {
    width: 100% !important;

    &:last-child {
      padding-left: 0;
    }
  }

  @media (min-width: 1200px) and (max-width: 1440px) {
    .lastSideElement {
      max-width: 50%;
    }
  }

  @media (min-width: 1440px) and (max-width: 1620px) {
    .lastSideElement {
      max-width: 1 / 3 * 100%;
    }
  }
}

.visitElement {
  h6 {
    font-size: 115%;
  }
}


.filtertag{
    color: #fff ;
    background-color: #858796 ;
    border-color: #858796 !important;
    display: inline-block !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    border-radius: 1.5rem !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.sparklineWrapper {
  display: flex;
  justify-content: center;
  min-width: calc(100% - 150px);
}

.customDropdown {
  margin-left: auto;
  margin-right: auto;
  & button{border-color: transparent!important;
  background-color: transparent !important;}
}


.notifications {
  :global .la {
    font-size: $font-size-lg;
    margin-top: 0.15rem;
  }

  p {
    margin-bottom: 1.4rem;
  }
}

:global .legendColorBox div {
  border-radius: 50%;
}
