@import '../../styles/app';

.root {
  .invoiceLogo {
    max-height: 50px;
  }

  .invoiceBody {
    margin-top: 70px;
  }

  :global {
    .widget {
      padding: 10px 20px;
    }
  }
}