@import '../../../../../../styles/app.scss';

:global {
  @import '../../../../../../../node_modules/react-datetime/css/react-datetime.css';
}

.root {
  :global {
    .tab-content {
      overflow: visible;
    }
  }

  .formWizard {
    :global {
      .progress {
        margin-top: 6px;
        margin-bottom: 10px;
      }

      .form-group:last-child {
        margin-bottom: 0;
      }

      .pager {
        display: flex;
        margin: 18px 0;
        flex-direction: row;
        justify-content: space-between;

        .disabled button {
          cursor: not-allowed;
          opacity: 0.65;
        }
      }

      #datepicker .input-group {
        width: 100%;
        display: flex !important;

        .form-control {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }

        .input-group-addon {
          display: none;
        }
      }
    }
  }

  .wizardNavigation {
    margin: 0 (-0.5 * $spacer) 5px;

    :global {
      .active {
        background: theme-color('primary');

        &:hover {
          color: $white;
        }
      }
    }

    li {
      padding: 0 (0.5 * $spacer);
    }

    a {
      padding: $spacer;
      color: $text-color;
      background: $gray-300;

      strong {
        font-weight: $font-weight-semi-bold;
      }

      small {
        font-weight: $font-weight-thin;
      }
    }
  }

  .stepBody {
    padding: $spacer;
    background: $subtle-white;
    border-radius: $border-radius;
  }
}
