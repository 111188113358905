@import '../../styles/app';

.root {
  :global {
    .post-comments footer {
      margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
      padding: $widget-padding-vertical $widget-padding-horizontal;
    }

    .stat-item .name {
      margin-top: 10px;
    }
  }
}

.table {
  :global(.table.table-sm) {
    td {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
}
